import { Box, Tooltip } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { textNodeModel } from 'shared/ui/text-node';
import { ToggleButton, ToggleButtonProps } from 'shared/ui/toggle-button';

export const AddTextNodeByButton = (props: ToggleButtonProps) => {
  const dispatch = whiteboardModel.useDispatch();

  const handleClick = () => {
    dispatch(
      whiteboardModel.createNode({
        type: whiteboardModel.WhiteboardNodeType.Text,
        props: textNodeModel.defaultConfig,
      }),
    );
  };

  return (
    <ToggleButton {...props} variant="text" onClick={handleClick}>
      <Tooltip title="Add text" placement="bottom">
        <Box>T</Box>
      </Tooltip>
    </ToggleButton>
  );
};
