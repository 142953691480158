import { Config } from './types';

export const defaultConfig: Config = {
  x: 50,
  y: 50,
  fill: 'black',
  text: 'Enter text',
  fontSize: 14,
  fontFamily: 'Montserrat',
  width: 150,
};
