import { TransformerConfig } from 'konva/lib/shapes/Transformer';

export enum Anchor {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  TopCenter = 'top-center',
  MiddleLeft = 'middle-left',
  MiddleRight = 'middle-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  BottomCenter = 'bottom-center',
}

export type Config = TransformerConfig;
