import { Children, PropsWithChildren, ReactElement } from 'react';

import { SwitchCaseProps } from './switch-case';
import { SwitchDefaultProps } from './switch-default';

export type SwitchProps = {
  condition: string;
};

export const Switch = ({ condition, children }: PropsWithChildren<SwitchProps>) => {
  const CaseComponent = (Children.toArray(children) as ReactElement<SwitchCaseProps | SwitchDefaultProps>[])
    .filter((child) => child.props.componentName === 'case')
    .find((child) => (child as ReactElement<SwitchCaseProps>).props.value === condition);

  if (CaseComponent) {
    return CaseComponent;
  }

  const DefaultComponent = (Children.toArray(children) as ReactElement<SwitchCaseProps | SwitchDefaultProps>[]).find(
    (child) => child.props.componentName === 'default',
  );

  return DefaultComponent || null;
};
