import { whiteboardModel } from 'entities/whiteboard';
import { AlignText } from 'features/node/align-text';
import { ChangeDimensions } from 'features/node/change-dimensions';
import { ChangeFontColor } from 'features/node/change-font-color';
import { ChangeFontFamily } from 'features/node/change-font-family';
import { ChangeFontSize } from 'features/node/change-font-size';
import { Fill } from 'features/node/fill';
import { Fragment, memo } from 'react';
import { Divider } from 'shared/ui/divider';
import { StyleFontCommands } from 'widgets/style-font-commands';

import { ToolboxItem } from './model';

export const ITEMS: ToolboxItem[] = [
  {
    nodes: [whiteboardModel.WhiteboardNodeType.Text],
    component: <ChangeFontFamily />,
  },
  {
    nodes: [whiteboardModel.WhiteboardNodeType.Text],
    component: <ChangeFontSize />,
  },
  {
    nodes: [whiteboardModel.WhiteboardNodeType.Text],
    component: <StyleFontCommands />,
  },
  {
    nodes: [whiteboardModel.WhiteboardNodeType.Text],
    component: <AlignText />,
  },
  {
    nodes: [whiteboardModel.WhiteboardNodeType.Text],
    component: <ChangeFontColor />,
  },
  {
    nodes: [
      whiteboardModel.WhiteboardNodeType.Circle,
      whiteboardModel.WhiteboardNodeType.Rect,
      whiteboardModel.WhiteboardNodeType.Triangle,
    ],
    component: <Fill />,
  },
  {
    nodes: [
      whiteboardModel.WhiteboardNodeType.Image,
      whiteboardModel.WhiteboardNodeType.Video,
      whiteboardModel.WhiteboardNodeType.Circle,
      whiteboardModel.WhiteboardNodeType.Rect,
      whiteboardModel.WhiteboardNodeType.Triangle,
    ],
    component: <ChangeDimensions />,
  },
];

export const NodeToolCommands = memo(() => {
  const selectedNodeType = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeType);

  return (
    <>
      {ITEMS.filter(({ nodes }) => nodes.includes(selectedNodeType!)).map(({ component }, idx) => (
        <Fragment key={idx}>
          <Divider />
          {component}
        </Fragment>
      ))}
    </>
  );
});
