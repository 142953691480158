import { makeSx } from 'shared/lib/theme';

export default makeSx<{ color?: string }>(({ color = 'black' }) => ({
  colorButton: {
    color,
    '&:hover': { color },
    '& svg': { height: 20, width: 20 },
  },
  transparent: ({ palette }) => ({
    '& svg': { stroke: palette.grey[4] },
  }),
}));
