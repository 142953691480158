import { CssBaseline, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

type ThemeProviderProps = {
  theme: Theme;
};

export const ThemeProvider = ({ theme, children }: PropsWithChildren<ThemeProviderProps>) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
