import { whiteboardModel } from 'entities/whiteboard';
import DeleteIcon from 'shared/assets/icons/Delete.svg';
import { ToggleButton } from 'shared/ui/toggle-button';

import useStyles from './styles';

export const RemoveNodeByButton = () => {
  const styles = useStyles();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const dispatch = whiteboardModel.useDispatch();

  const handleRemove = () => {
    dispatch(whiteboardModel.removeNode(selectedNodeProps!.id!));
  };

  return (
    <ToggleButton value="trash" sx={styles.root} onClick={handleRemove}>
      <DeleteIcon />
    </ToggleButton>
  );
};
