import { ModeChangeListener } from 'features/change-mode';
import { ClipboardListener } from 'features/clipboard';
import { RemoveNodeHotKeyListener } from 'features/whiteboard/remove-node';

export const HotKeyListener = () => (
  <>
    <ClipboardListener />
    <RemoveNodeHotKeyListener />
    <ModeChangeListener />
  </>
);
