import { FunctionComponent } from 'react';

export type SwitchCaseProps = {
  componentName?: 'case';
  value: string | number | boolean;
};

export const SwitchCase: FunctionComponent<SwitchCaseProps> = ({ children }) => {
  return <>{children}</>;
};

SwitchCase.defaultProps = {
  componentName: 'case',
};
