import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type VideoPlayerState = {
  isPlaying: boolean;
  progress: number;
  speed: number;
  isMuted: boolean;
};

type VideoPlayerMethods = {
  togglePlay: () => void;
  toggleMute: () => void;
  setTrack: (time: number) => void;
};

type VideoPlayerContext = [player: HTMLVideoElement | undefined, state: VideoPlayerState, methods: VideoPlayerMethods];

const Context = createContext<VideoPlayerContext>(null!);

type VideoPlayerProviderProps = {
  src: string;
};

export const VideoPlayerProvider = ({ src, children }: PropsWithChildren<VideoPlayerProviderProps>) => {
  const [videoUrl, setVideoUrl] = useState('');

  const player = useMemo(() => {
    if (videoUrl) {
      const element = document.createElement('video');
      element.src = videoUrl;
      element.currentTime = 0.1;

      return element;
    }
  }, [videoUrl]);

  const [state, setState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
  });

  const togglePlay = useCallback(() => {
    setState((prevState) => ({ ...prevState, isPlaying: !prevState.isPlaying }));
  }, []);

  const toggleMute = useCallback(() => {
    setState((prevState) => ({ ...prevState, isMuted: !prevState.isMuted }));
  }, []);

  const setTrack = useCallback(
    (progress: number) => {
      if (player) {
        player.currentTime = (player.duration / 100) * progress;
        setState((prevState) => ({ ...prevState, progress }));
      }
    },
    [player],
  );

  const updateTime = useCallback(() => {
    if (player) {
      const progress = (player.currentTime / player.duration) * 100;
      setState((prevState) => ({ ...prevState, progress }));
    }
  }, [player]);

  useEffect(() => {
    if (player) {
      player.addEventListener('ended', togglePlay);
      player.addEventListener('timeupdate', updateTime);

      return () => {
        player.removeEventListener('ended', togglePlay);
        player.removeEventListener('timeupdate', updateTime);
      };
    }
  }, [player, togglePlay, updateTime]);

  useEffect(() => {
    if (player) {
      state.isPlaying ? player.play() : player.pause();
    }
  }, [player, state.isPlaying]);

  useEffect(() => {
    if (player) {
      player.muted = state.isMuted;
    }
  }, [player, state.isMuted]);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(src);
        const blob = await response.blob();
        setVideoUrl(URL.createObjectURL(blob));
      } catch (e) {
        console.log(e);
      }
    };

    fetchVideo();
  }, [src]);

  const context = useMemo<VideoPlayerContext>(
    () => [player, state, { togglePlay, toggleMute, setTrack }],
    [player, state, togglePlay, toggleMute, setTrack],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useVideoPlayer = () => {
  const context = useContext(Context);

  return context;
};
