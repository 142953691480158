import { Box } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { ColorChangeHandler } from 'react-color';
import { useToggle } from 'shared/lib/toggle';
import { ColorPicker } from 'shared/ui/color-picker';
import { ToggleButton } from 'shared/ui/toggle-button';

import useStyles from './styles';

export const ChangeFontColor = () => {
  const dispatch = whiteboardModel.useDispatch();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const id = selectedNodeProps?.id;
  const selectedColor = selectedNodeProps?.fill;

  const styles = useStyles({ color: selectedColor! });

  const [colorPickerOpened, toggleColorPicker] = useToggle(false);

  const handleChange: ColorChangeHandler = ({ hex }) => {
    dispatch(whiteboardModel.transformNode({ id, fill: hex }));
  };

  return (
    <Box>
      <ToggleButton sx={styles.trigger} value="color" variant="text" onClick={toggleColorPicker}>
        A
      </ToggleButton>
      <ColorPicker
        opened={colorPickerOpened}
        color={selectedColor}
        onChange={handleChange}
        onClose={toggleColorPicker}
      />
    </Box>
  );
};
