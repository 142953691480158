import { MenuItem, Select, styled } from '@mui/material';
import CaretDownIcon from 'shared/assets/icons/Caret Down.svg';

export const ToolboxSelectItem = styled(MenuItem)({});

export const ToolboxSelect = styled(Select)<{ width?: number }>(({ theme: { spacing }, width = 150 }) => ({
  width,
  height: 40,
  div: {
    paddingLeft: spacing(2),
  },
  svg: {
    right: 10,
  },
}));

ToolboxSelect.defaultProps = {
  disableUnderline: true,
  MenuProps: {
    PaperProps: {
      elevation: 1,
      sx: {
        marginTop: 0.5,
        borderRadius: `0 0 4px 4px`,
        border: ({ palette }) => `1px solid ${palette.grey[2]}`,
      },
    },
  },
  IconComponent: CaretDownIcon,
};
