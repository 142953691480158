import { Components } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';

import { paletteConfig } from './palette';

const colors = createPalette(paletteConfig);

export const componentsConfig: Components = {
  MuiCssBaseline: {
    styleOverrides: `
      body {
        background-color: transparent;
      };
      #root {
        height: 100vh;
      };
    `,
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiInput: {
    defaultProps: {
      disableUnderline: true,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      iconStandard: {
        color: colors.grey[4],
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: `4px 16px`,
        '&:hover': {
          backgroundColor: colors.grey[3],
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 32,
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      PaperProps: {
        elevation: 1,
      },
    },
    styleOverrides: {
      list: {
        padding: 0,
      },
      paper: {
        borderRadius: `0 0 4px 4px`,
        border: `1px solid ${colors.grey[2]}`,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: 40,
        minHeight: 40,
        padding: '0 16px',
        '&:hover': {
          backgroundColor: `${colors.grey[3]} !important`,
        },
        '&.Mui-selected': {
          backgroundColor: `${colors.grey[3]} !important`,
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      disableInteractive: true,
    },
    styleOverrides: {
      tooltip: {
        padding: '5px 8px',
        fontSize: 14,
        backgroundColor: colors.grey[7],
        color: colors.common.white,
      },
      arrow: {
        '&::before': {
          backgroundColor: colors.grey[7],
        },
      },
    },
  },
};
