import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { ColorChangeHandler } from 'react-color';
import { useToggle } from 'shared/lib/toggle';
import { ColorPicker } from 'shared/ui/color-picker';
import { ToggleButton } from 'shared/ui/toggle-button';

import useStyles from './styles';

export const Fill = () => {
  const dispatch = whiteboardModel.useDispatch();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const id = selectedNodeProps?.id;
  const selectedColor = selectedNodeProps?.fill;

  const styles = useStyles({ color: selectedColor });

  const [colorPickerOpened, toggleColorPicker] = useToggle(false);

  const isWhite = (hex: string) => hex === '#ffffff' || hex === 'transparent';

  const handleChange: ColorChangeHandler = ({ hex }) => {
    dispatch(whiteboardModel.transformNode({ id, fill: hex, stroke: isWhite(hex) ? 'black' : hex }));
  };

  return (
    <Box>
      <ToggleButton
        sx={[styles.colorButton, isWhite(selectedColor!) && styles.transparent]}
        value="fill"
        onClick={toggleColorPicker}
      >
        <CircleIcon />
      </ToggleButton>
      <ColorPicker
        opened={colorPickerOpened}
        color={selectedColor}
        onChange={handleChange}
        onClose={toggleColorPicker}
      />
    </Box>
  );
};
