import { Box } from '@mui/material';
import { Stage as StageRef } from 'konva/lib/Stage';
import { PropsWithChildren, useRef } from 'react';
import useDimensions from 'react-cool-dimensions';
import { Layer, Stage, StageProps } from 'react-konva';

import { CanvasRefContext } from './canvas-ref';

export type CanvasProps = StageProps;

export const Canvas = ({ children, ...props }: PropsWithChildren<CanvasProps>) => {
  const { observe, height, width } = useDimensions<HTMLDivElement>();

  const stageRef = useRef<StageRef>(null!);

  return (
    <Box ref={observe} height="100%" width="100%">
      <Stage {...props} ref={stageRef} height={height} width={width}>
        <Layer>
          <CanvasRefContext.Provider value={stageRef}>{children}</CanvasRefContext.Provider>
        </Layer>
      </Stage>
    </Box>
  );
};
