import { useTheme } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import Konva from 'konva';
import { KonvaEventObject, Node, NodeConfig } from 'konva/lib/Node';
import { Rect, RectConfig } from 'konva/lib/shapes/Rect';
import { Transformer as TransformerRef } from 'konva/lib/shapes/Transformer';
import { IRect, Vector2d } from 'konva/lib/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Transformer } from 'react-konva';
import { useCanvasRef } from 'shared/ui/canvas';
import { connectorModel } from 'shared/ui/connector';
import { useNodeRef } from 'shared/ui/node';
import { transformerModel } from 'shared/ui/transformer';

import { PreviewConnector } from './connector-preview';

type AnchorListProps = {
  enabledAnchors?: transformerModel.Anchor[];
};

const DEFAULT_ENABLED_ANCHORS = Object.values(transformerModel.Anchor);

export const AnchorList = ({ enabledAnchors = DEFAULT_ENABLED_ANCHORS }: AnchorListProps) => {
  const { palette } = useTheme();

  const dispatch = whiteboardModel.useDispatch();
  const isConnectorMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeConnect);

  const nodeRef = useNodeRef();
  const canvasRef = useCanvasRef();
  const anchorRef = useRef<TransformerRef>(null);

  const [pointerAnchor, setPointerAnchor] = useState<transformerModel.Anchor>();
  const [pointerStartPoints, setPointerStartPoints] = useState<number[]>([]);
  const [pointerEndPoints, setPointerEndPoints] = useState<number[]>([]);

  const previewConnectorPoints = useMemo(
    () => [...pointerStartPoints, ...pointerEndPoints],
    [pointerStartPoints, pointerEndPoints],
  );

  const getPointerRect = ({ x, y }: Vector2d): IRect => ({ x, y, width: 6, height: 6 });
  const getPointerPosition = () => canvasRef.current.getPointerPosition()!;
  const getRelativePointerPosition = () => canvasRef.current.getRelativePointerPosition();

  const setStartPointer = ({ currentTarget }: KonvaEventObject<Event>) => {
    const { x, y } = getPointerPosition()!;
    const pointer = getPointerRect({ x, y });

    const targetAnchor = (currentTarget as TransformerRef).children?.find((anchor) => {
      if (anchor instanceof Rect) {
        return Konva.Util.haveIntersection(anchor.getClientRect(), pointer);
      }
      return false;
    });

    setPointerAnchor(targetAnchor?.name().split(' ')[0] as transformerModel.Anchor);
    const { x: relX, y: relY } = getRelativePointerPosition()!;

    setPointerStartPoints([relX, relY]);
  };

  const drawConnector = () => {
    const { x, y } = getRelativePointerPosition();
    setPointerEndPoints([x, y]);
  };

  const setEndPointer = () => {
    setPointerStartPoints([]);
    setPointerEndPoints([]);

    const [layer] = canvasRef.current.getLayers();

    const { x, y } = getPointerPosition();
    const endPointerRect: IRect = { x, y, height: 6, width: 6 };

    const anchorTransformers = layer.getChildren((child) => {
      if (child instanceof TransformerRef) {
        return child.id() !== anchorRef.current?.id() && child.getAttr('visible');
      }

      return false;
    }) as TransformerRef[];

    let targetNode: Node<NodeConfig> | null = null;
    let targetAnchor: Node<RectConfig> | null = null;

    for (const transformer of anchorTransformers) {
      targetAnchor = transformer.children?.find((anchor) => {
        if (anchor instanceof Rect) {
          return Konva.Util.haveIntersection(
            anchor.getClientRect({ relativeTo: canvasRef.current as any }),
            endPointerRect,
          );
        }
        return false;
      }) as Node<RectConfig>;

      if (targetAnchor) {
        targetNode = transformer.getNode();
        break;
      }
    }

    if (targetNode && targetAnchor) {
      const sourceNodeId = nodeRef.current?.id();
      const sourceAnchorId = pointerAnchor!;
      const targetNodeId = targetNode.id();
      const targetAnchorId = targetAnchor.name().split(' ')[0] as transformerModel.Anchor;

      dispatch(
        whiteboardModel.createNode({
          type: whiteboardModel.WhiteboardNodeType.Connector,
          draft: false,
          props: {
            ...connectorModel.defaultConfig,
            source: { id: sourceNodeId, anchor: sourceAnchorId },
            target: { id: targetNodeId, anchor: targetAnchorId },
          },
        }),
      );
    }
  };

  useEffect(() => {
    anchorRef.current?.nodes([nodeRef.current]);
  }, [anchorRef, nodeRef]);

  return (
    <>
      <Transformer
        id={`${nodeRef.current?.id()}-transformer`}
        ref={anchorRef}
        visible={isConnectorMode}
        anchorSize={8}
        anchorStrokeWidth={0}
        enabledAnchors={enabledAnchors}
        anchorFill={palette.primary.main}
        borderStroke={palette.primary.main}
        rotateEnabled={false}
        boundBoxFunc={(prev) => prev}
        onTransformStart={setStartPointer}
        onTransform={drawConnector}
        onTransformEnd={setEndPointer}
      />
      <PreviewConnector points={previewConnectorPoints} />
    </>
  );
};
