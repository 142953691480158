import compose from 'compose-function';
import { ComponentType, FunctionComponent } from 'react';

import { WithFileStorage, withFileStorage } from './with-file-storage';
import { withFonts } from './with-fonts';
import { withStore } from './with-store';
import { WithTheme, withTheme } from './with-theme';

type WithProviders = <T>(Component: ComponentType<T>) => FunctionComponent<WithTheme & WithFileStorage & T>;

export const withProviders: WithProviders = compose(withStore, withTheme, withFonts, withFileStorage);
