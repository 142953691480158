import { Box, Slider } from '@mui/material';
import { IRect } from 'konva/lib/types';
import { SyntheticEvent, useEffect, useState } from 'react';
import MuteOffIcon from 'shared/assets/icons/MuteOff.svg';
import MuteOnIcon from 'shared/assets/icons/MuteOn.svg';
import PauseIcon from 'shared/assets/icons/Pause.svg';
import PlayIcon from 'shared/assets/icons/Play.svg';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useToggle } from 'shared/lib/toggle';
import { useCanvasRef } from 'shared/ui/canvas';
import { InjectedHtml } from 'shared/ui/injected-html';
import { useNodeRef } from 'shared/ui/node';
import { ToggleButton } from 'shared/ui/toggle-button';
import { useVideoPlayer } from 'shared/ui/video-player/lib';

import useStyles from './styles';

const TOOLBOX_HEIGHT = 40;

export const VideoControls = () => {
  const ref = useNodeRef();
  const canvasRef = useCanvasRef();
  const [, { isPlaying, isMuted, progress }, { togglePlay, toggleMute, setTrack }] = useVideoPlayer();

  const [box, setBox] = useState<Omit<IRect, 'height'>>({ x: 0, y: 0, width: 0 });
  const [visible, toggleVisible] = useToggle();

  const styles = useStyles({ width: box.width, height: TOOLBOX_HEIGHT, visible });

  const getClientRect = () => ref.current.getClientRect({ relativeTo: canvasRef.current });

  const setDimensions = (parentBox: IRect) => {
    const x = parentBox.x;
    const y = parentBox.y + parentBox.height;

    setBox({ x, y, width: parentBox.width });
  };

  const handleTrack = (_: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
    setTrack(value as number);
  };

  useEffect(() => {
    setDimensions(getClientRect());
  }, [ref]);

  useCanvasEvent('dragmove', ref, () => {
    setDimensions(getClientRect());
  });

  useCanvasEvent('transform', ref, () => {
    setDimensions(getClientRect());
  });

  return (
    <InjectedHtml groupProps={{ x: box?.x, y: box?.y }}>
      <Box sx={styles.root} onMouseEnter={toggleVisible} onMouseLeave={toggleVisible} contentEditable={false}>
        <ToggleButton value="playback" sx={styles.control} onClick={togglePlay}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </ToggleButton>
        <Slider size="small" value={progress} onChange={handleTrack} />
        <ToggleButton value="sound" sx={styles.control} onClick={toggleMute}>
          {isMuted ? <MuteOnIcon /> : <MuteOffIcon />}
        </ToggleButton>
      </Box>
    </InjectedHtml>
  );
};
