import { makeSx } from 'shared/lib/theme';

export default makeSx<{ color: string }>(({ color = 'black' }) => ({
  trigger: {
    '&::after': {
      content: '""',
      backgroundColor: color,
      position: 'absolute',
      width: 20,
      height: 3,
      bottom: 3,
      borderRadius: 2,
    },
  },
}));
