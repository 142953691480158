import { alpha } from '@mui/material';
import { makeSx } from 'shared/lib/theme';

export default makeSx<{ width: number; height: number; visible: boolean }>(({ width, height, visible }) => ({
  root: ({ palette, spacing, transitions }) => ({
    width,
    height,
    display: 'flex',
    opacity: visible ? 1 : 0,
    transition: transitions.create('opacity', {
      duration: 200,
    }),
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
    backgroundColor: alpha(palette.grey[8], 0.5),
    padding: [spacing(0), spacing(1)],
  }),
  control: {
    color: 'white',
  },
}));
