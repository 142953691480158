import { whiteboardModel } from 'entities/whiteboard';
import { MouseEvent } from 'react';
import { ToggleButton } from 'shared/ui/toggle-button';
import { ToggleButtonGroup } from 'shared/ui/toggle-button-group';

export const ChangeFontStyle = () => {
  const dispatch = whiteboardModel.useDispatch();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const id = selectedNodeProps?.id;
  const selectedFontStyle = selectedNodeProps?.fontStyle;

  const handleChange = (_: MouseEvent<HTMLElement>, fontStyle: string) => {
    dispatch(whiteboardModel.transformNode({ id, fontStyle }));
  };

  return (
    <ToggleButtonGroup exclusive value={selectedFontStyle} onChange={handleChange}>
      <ToggleButton variant="text" value="italic" sx={{ fontStyle: 'italic' }}>
        I
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
