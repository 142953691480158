import { whiteboardModel } from 'entities/whiteboard';
import { AnchorList } from 'features/connect';
import { EditableTextNode } from 'features/edit-text-node';
import { DragListener } from 'features/node/drag';
import { NodeSelectionListener } from 'features/selection';
import { NodeTransformListener } from 'features/transform';
import { VideoControls } from 'features/video-controls';
import { useMemo } from 'react';
import { CircleNode } from 'shared/ui/circle-node';
import { Connector, connectorModel } from 'shared/ui/connector';
import { ImageNode, imageNodeModel } from 'shared/ui/image-node';
import { RectNode } from 'shared/ui/rect-node';
import { Switch, SwitchCase, SwitchDefault } from 'shared/ui/switch-case';
import { transformerModel } from 'shared/ui/transformer';
import { TriangleNode, triangleNodeModel } from 'shared/ui/triangle-node';
import { VideoPlayer, videoPlayerModel } from 'shared/ui/video-player';
import { NodeContextMenu } from 'widgets/node-context-menu';

export const WhiteboardNodeList = () => {
  const nodes = whiteboardModel.useSelector(whiteboardModel.selectNodeList);

  const NodePlugins = useMemo(
    () => (
      <>
        <NodeSelectionListener />
        <DragListener />
        <NodeContextMenu />
      </>
    ),
    [],
  );

  return (
    <>
      {nodes.map(({ type, props }) => (
        <Switch condition={type} key={props.id}>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Text}>
            <EditableTextNode {...props}>
              {NodePlugins}
              <NodeTransformListener disableY />
              <AnchorList />
            </EditableTextNode>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Rect}>
            <RectNode {...props}>
              {NodePlugins}
              <NodeTransformListener />
              <AnchorList />
            </RectNode>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Circle}>
            <CircleNode {...props}>
              {NodePlugins}
              <NodeTransformListener />
              <AnchorList
                enabledAnchors={[
                  transformerModel.Anchor.TopCenter,
                  transformerModel.Anchor.BottomCenter,
                  transformerModel.Anchor.MiddleLeft,
                  transformerModel.Anchor.MiddleRight,
                ]}
              />
            </CircleNode>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Triangle}>
            <TriangleNode {...(props as triangleNodeModel.Config)}>
              {NodePlugins}
              <NodeTransformListener />
              <AnchorList
                enabledAnchors={[
                  transformerModel.Anchor.TopCenter,
                  transformerModel.Anchor.BottomCenter,
                  transformerModel.Anchor.BottomLeft,
                  transformerModel.Anchor.BottomRight,
                ]}
              />
            </TriangleNode>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Image}>
            <ImageNode {...(props as imageNodeModel.Config)}>
              {NodePlugins}
              <NodeTransformListener />
              <AnchorList />
            </ImageNode>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Video}>
            <VideoPlayer {...(props as videoPlayerModel.Config)}>
              {NodePlugins}
              <VideoControls />
              <NodeTransformListener />
              <AnchorList />
            </VideoPlayer>
          </SwitchCase>
          <SwitchCase value={whiteboardModel.WhiteboardNodeType.Connector}>
            <Connector {...(props as connectorModel.Config)}>
              <NodeSelectionListener />
            </Connector>
          </SwitchCase>
          <SwitchDefault />
        </Switch>
      ))}
    </>
  );
};
