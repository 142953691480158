import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import WebFont from 'webfontloader';

const Context = createContext<string[]>([]);

type FontProviderProps = {
  families: string[];
};

export const FontProvider = ({ families, children }: PropsWithChildren<FontProviderProps>) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    WebFont.load({
      google: { families: families.sort() },
      active: () => setInitialized(true),
    });
  }, [families]);

  return <Context.Provider value={families}>{children}</Context.Provider>;
};

export const useFonts = () => useContext(Context);
