import { createSelector } from 'reselect';

import { State, WhiteboardMode } from './types';

const stateSelector = (state: State) => state;

export const selectMode = createSelector(stateSelector, ({ mode }) => mode);
export const selectIsModeView = createSelector(selectMode, (mode) => mode === WhiteboardMode.View);
export const selectIsModeConnect = createSelector(selectMode, (mode) => mode === WhiteboardMode.Connect);
export const selectIsModeEdit = createSelector(selectMode, (mode) => mode === WhiteboardMode.Edit);

export const selectNodeListWithDrafts = createSelector(stateSelector, ({ nodes }) => nodes);
export const selectNodeList = createSelector(selectNodeListWithDrafts, (nodes) => nodes.filter(({ draft }) => !draft));

export const selectDrafts = createSelector(selectNodeListWithDrafts, (list) => list.find(({ draft }) => draft));
export const selectHasDrafts = createSelector(selectDrafts, (drafts) => !!drafts);

export const selectSelectedNodeIdsWithDrafts = createSelector(stateSelector, ({ selectedNodeIds }) => selectedNodeIds);
export const selectSelectedNodeListWithDrafts = createSelector(
  selectSelectedNodeIdsWithDrafts,
  selectNodeListWithDrafts,
  (ids, nodes) => nodes.filter(({ props: { id } }) => ids.includes(id!)),
);
export const selectSelectedNode = createSelector(selectSelectedNodeListWithDrafts, (nodes) =>
  nodes.length === 1 ? nodes[0] : undefined,
);
export const selectSelectedNodeProps = createSelector(selectSelectedNode, (node) => node?.props);
export const selectSelectedNodeType = createSelector(selectSelectedNode, (node) => node?.type);

export const selectSelectedNodeList = createSelector(selectSelectedNodeListWithDrafts, (nodes) =>
  nodes.filter(({ draft }) => !draft),
);
export const selectSelectedNodeIds = createSelector(selectSelectedNodeList, (nodes) =>
  nodes.map(({ props: { id } }) => id),
);
