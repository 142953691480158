import { useTheme } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { Transformer as TransformerRef } from 'konva/lib/shapes/Transformer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Transformer } from 'react-konva';
import { useCanvasRef } from 'shared/ui/canvas';

import { TransformConfig } from '../model';
import { useAnchors } from '.';

type GlobalTransformListenerProps = TransformConfig;

export const GlobalTransformListener = (props: GlobalTransformListenerProps) => {
  const { palette } = useTheme();

  const canvasRef = useCanvasRef();
  const transformerRef = useRef<TransformerRef>(null!);

  const selectedNodes = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeList);
  const isEditMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeEdit);
  const selectedNodesRefs = useMemo(
    () => selectedNodes.map(({ props: { id } }) => canvasRef.current.findOne(`#${id}`)).filter((ref) => !!ref),
    [selectedNodes, canvasRef],
  );

  const singleSelected = selectedNodes.length === 1;

  const [nodeType, setNodeType] = useState<whiteboardModel.WhiteboardNodeType>();
  const enabledAnchors = useAnchors(nodeType);

  useEffect(() => {
    singleSelected ? setNodeType(selectedNodes[0].type) : setNodeType(undefined);
  }, [singleSelected, selectedNodes]);

  useEffect(() => {
    transformerRef.current.nodes(selectedNodesRefs);
  }, [selectedNodesRefs]);

  return (
    <Transformer
      {...props}
      ref={transformerRef}
      visible={isEditMode}
      anchorSize={8}
      anchorStrokeWidth={0}
      flipEnabled={false}
      rotateEnabled={false}
      enabledAnchors={enabledAnchors}
      anchorFill={palette.secondary.main}
      borderStroke={palette.secondary.main}
    />
  );
};
