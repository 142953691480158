import { Text as TextRef } from 'konva/lib/shapes/Text';
import { PropsWithChildren } from 'react';
import { KonvaNodeEvents, Text } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';

import { Config } from './model';

export type TextNodeProps = KonvaNodeEvents & Config;

const TextNodeInner = (props: TextNodeProps) => {
  const ref = useNodeRef<TextRef>();

  return <Text {...props} ref={ref} padding={12} />;
};

export const TextNode = ({ children, ...props }: PropsWithChildren<TextNodeProps>) => (
  <NodeRefProvider>
    <TextNodeInner {...props} />
    {children}
  </NodeRefProvider>
);
