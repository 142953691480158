import ChangeHistoryRoundedIcon from '@mui/icons-material/ChangeHistoryRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CropSquareOutlinedIcon from '@mui/icons-material/CropSquareOutlined';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { MouseEvent, useState } from 'react';
import ShapeIcon from 'shared/assets/icons/Shape.svg';
import { circleNodeModel } from 'shared/ui/circle-node';
import { rectNodeModel } from 'shared/ui/rect-node';
import { ToggleButton, ToggleButtonProps } from 'shared/ui/toggle-button';
import { triangleNodeModel } from 'shared/ui/triangle-node';

import useStyles from './styles';

const SHAPE_ICON_LIST = {
  [whiteboardModel.WhiteboardNodeType.Rect]: <CropSquareOutlinedIcon />,
  [whiteboardModel.WhiteboardNodeType.Circle]: <CircleOutlinedIcon />,
  [whiteboardModel.WhiteboardNodeType.Triangle]: <ChangeHistoryRoundedIcon />,
};

export const AddShapeNodeByButton = (props: ToggleButtonProps) => {
  const styles = useStyles();

  const dispatch = whiteboardModel.useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddShapeClick = (type: whiteboardModel.WhiteboardNodeType) => {
    switch (type) {
      case whiteboardModel.WhiteboardNodeType.Rect: {
        dispatch(
          whiteboardModel.createNode({
            type: whiteboardModel.WhiteboardNodeType.Rect,
            props: rectNodeModel.defaultConfig,
          }),
        );
        break;
      }
      case whiteboardModel.WhiteboardNodeType.Circle: {
        dispatch(
          whiteboardModel.createNode({
            type: whiteboardModel.WhiteboardNodeType.Circle,
            props: circleNodeModel.defaultConfig,
          }),
        );
        break;
      }
      case whiteboardModel.WhiteboardNodeType.Triangle: {
        dispatch(
          whiteboardModel.createNode({
            type: whiteboardModel.WhiteboardNodeType.Triangle,
            props: triangleNodeModel.defaultConfig,
          }),
        );
        break;
      }
    }

    setAnchorEl(null);
  };

  return (
    <>
      <ToggleButton {...props} onClick={handleOpen}>
        <Tooltip title="Add shape">
          <ShapeIcon />
        </Tooltip>
      </ToggleButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 1,
          sx: styles.paper,
        }}
      >
        <List disablePadding>
          {Object.entries(SHAPE_ICON_LIST).map(([shape, icon]) => (
            <ListItem
              disablePadding
              key={shape}
              onClick={() => handleAddShapeClick(shape as whiteboardModel.WhiteboardNodeType)}
            >
              <ListItemButton>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={shape} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};
