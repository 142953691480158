import { whiteboardModel } from 'entities/whiteboard';
import { useMemo } from 'react';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useNodeRef } from 'shared/ui/node';

export const NodeSelectionListener = () => {
  const nodeRef = useNodeRef();

  const selectedNodeIds = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeIds);
  const dispatch = whiteboardModel.useDispatch();

  const isSelected = useMemo(() => selectedNodeIds.includes(nodeRef.current?.id()), [selectedNodeIds, nodeRef]);

  const select = () => {
    if (!isSelected) {
      dispatch(whiteboardModel.updateNodeSelection([nodeRef.current.id()]));
    }
  };

  useCanvasEvent('click', nodeRef, select);
  useCanvasEvent('dragstart', nodeRef, select);

  return null;
};
