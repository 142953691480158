import { useTheme } from '@mui/material';
import { Arrow } from 'react-konva';

type PreviewConnectorProps = {
  points: number[];
};

export const PreviewConnector = ({ points }: PreviewConnectorProps) => {
  const { palette } = useTheme();

  return points.length ? (
    <Arrow
      points={points}
      strokeWidth={2}
      pointerLength={4}
      pointerWidth={4}
      fill={palette.secondary.dark}
      stroke={palette.secondary.dark}
    />
  ) : (
    <></>
  );
};
