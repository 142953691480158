import { whiteboardModel } from 'entities/whiteboard';
import { useEffect } from 'react';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useCanvasRef } from 'shared/ui/canvas';

export const WhiteBoardCursor = () => {
  const canvasRef = useCanvasRef();

  const drafts = whiteboardModel.useSelector(whiteboardModel.selectDrafts);
  const hasDrafts = whiteboardModel.useSelector(whiteboardModel.selectHasDrafts);
  const dispatch = whiteboardModel.useDispatch();

  useEffect(() => {
    canvasRef.current.container().style.cursor = hasDrafts ? 'crosshair' : 'default';
  }, [canvasRef, hasDrafts]);

  useCanvasEvent('mousedown', canvasRef, ({ target }) => {
    if (hasDrafts && target === target.getStage()) {
      const { x, y } = target.getRelativePointerPosition();

      dispatch(whiteboardModel.transformNode({ id: drafts?.props.id, x, y }));
      dispatch(whiteboardModel.commitDrafts());
    }
  });

  return null;
};
