import { configureStore, createSlice } from '@reduxjs/toolkit';
import { videoPlayerModel } from 'shared/ui/video-player';

import { slice, State, WhiteboardMode, WhiteboardNodeType } from '../model';

export const defaultState: State = {
  mode: WhiteboardMode.Connect,
  selectedNodeIds: [],
  nodes: [
    // {
    //   type: WhiteboardNodeType.Text,
    //   props: { id: '1', ...textNodeModel.defaultConfig },
    // },
    // {
    //   type: WhiteboardNodeType.Text,
    //   props: { id: '2', ...textNodeModel.defaultConfig, x: 350, y: 350 },
    // },
    // {
    //   type: WhiteboardNodeType.Rect,
    //   props: { id: '3', ...rectNodeModel.defaultConfig, x: 150, y: 150, fill: 'black' },
    // },
    // {
    //   type: WhiteboardNodeType.Circle,
    //   props: { id: '4', ...circleNodeModel.defaultConfig, x: 500, y: 150, fill: 'transparent' },
    // },
    // {
    //   type: WhiteboardNodeType.Triangle,
    //   props: { id: '5', ...triangleNodeModel.defaultConfig, x: 350, y: 150, fill: 'transparent' },
    // },
    {
      type: WhiteboardNodeType.Video,
      props: {
        ...videoPlayerModel.defaultConfig,
        image: undefined,
        x: 572,
        y: 353,
        src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
        id: 'yh9amr0p6m',
      },
    },
    {
      type: WhiteboardNodeType.Video,
      props: {
        ...videoPlayerModel.defaultConfig,
        image: undefined,
        x: 272,
        y: 353,
        src: 'https://api.zombty.com/v1/cmm-api/bucket/04bdad57-ed2c-462c-9f18-83120e5576bc/file/file_example_MP4_480_1_5MG.mp4/shared',
        id: 'vlqcnh5pfb',
      },
    },
    {
      type: WhiteboardNodeType.Video,
      props: {
        ...videoPlayerModel.defaultConfig,
        image: undefined,
        x: 872,
        y: 353,
        src: 'https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4',
        id: 'vlqcnh5pf2',
      },
    },
  ],
};

export const generateSlice = (state: State = defaultState) =>
  createSlice({
    name: slice.name,
    initialState: state,
    reducers: slice.caseReducers,
  });

export const generateStore = (state?: State) =>
  configureStore({
    reducer: {
      [slice.name]: generateSlice(state).reducer,
    },
  });
