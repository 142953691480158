import { whiteboardModel } from 'entities/whiteboard';
import { MouseEvent } from 'react';
import AlignCenterIcon from 'shared/assets/icons/Align Center.svg';
import AlignLeftIcon from 'shared/assets/icons/Align Left.svg';
import AlignRightIcon from 'shared/assets/icons/Align Right.svg';
import AlignJustifyIcon from 'shared/assets/icons/Justify.svg';
import { ToggleButton } from 'shared/ui/toggle-button';
import { ToggleButtonGroup } from 'shared/ui/toggle-button-group';

import { TextAlign } from './model';

export const AlignText = () => {
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const dispatch = whiteboardModel.useDispatch();

  const id = selectedNodeProps?.id;
  const selectedAlignOption: TextAlign = selectedNodeProps?.align || 'left';

  const handleChange = (e: MouseEvent<HTMLElement>, align: TextAlign) => {
    dispatch(whiteboardModel.transformNode({ id, align }));
  };

  return (
    <ToggleButtonGroup exclusive value={selectedAlignOption} onChange={handleChange}>
      <ToggleButton value="left">
        <AlignLeftIcon />
      </ToggleButton>
      <ToggleButton value="center">
        <AlignCenterIcon />
      </ToggleButton>
      <ToggleButton value="right">
        <AlignRightIcon />
      </ToggleButton>
      <ToggleButton value="justify">
        <AlignJustifyIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
