import { whiteboardModel } from 'entities/whiteboard';
import { useEffect } from 'react';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useNodeRef } from 'shared/ui/node';

export const DragListener = () => {
  const ref = useNodeRef();
  const isViewMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeView);

  const dispatch = whiteboardModel.useDispatch();

  useEffect(() => {
    ref.current.setDraggable(!isViewMode);
  }, [ref, isViewMode]);

  useCanvasEvent('dragstart', ref, () => {
    const { id } = ref.current.attrs;

    dispatch(whiteboardModel.moveToTop(id));
  });

  useCanvasEvent('dragend', ref, () => {
    const { id, x, y } = ref.current.attrs;

    dispatch(whiteboardModel.transformNode({ id, x, y }));
  });

  return null;
};
