import App from 'app';
import { defaultState } from 'entities/whiteboard/lib/mock';
import { storageMocked } from 'features/file-storage/lib/mock';
import { createWebComponent } from 'shared/lib/web-component';

if (process.env.NODE_ENV === 'production') {
  window.WhiteBoardWebComponent = createWebComponent(App);

  customElements.define('whiteboard-web-component', window.WhiteBoardWebComponent);
} else {
  window.WhiteBoardWebComponent = createWebComponent(App);

  customElements.define('whiteboard-web-component', window.WhiteBoardWebComponent);

  const root = document.createElement('div');

  const wb = new window.WhiteBoardWebComponent({
    items: defaultState.nodes,
    storage: storageMocked,
    onItemsChange: console.log,
  });

  root.appendChild(wb);

  root.id = 'root';
  root.style = 'height: 100%vh; display: grid; grid-template-rows: 1fr; overflow: hidden;';
  document.body.appendChild(root);
}
