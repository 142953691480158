import { Box, SelectChangeEvent } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { useFonts } from 'shared/lib/font-loader';
import { ToolboxSelect, ToolboxSelectItem } from 'shared/ui/toolbox-select';

export const ChangeFontFamily = () => {
  const fonts = useFonts();

  const dispatch = whiteboardModel.useDispatch();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const id = selectedNodeProps?.id;
  const selectedFontFamily = selectedNodeProps?.fontFamily;

  const handleChange = ({ target: { value: fontFamily } }: SelectChangeEvent<any>) => {
    dispatch(whiteboardModel.transformNode({ id, fontFamily }));
  };

  return (
    <Box>
      <ToolboxSelect value={selectedFontFamily} onChange={handleChange}>
        {fonts.map((font) => (
          <ToolboxSelectItem key={font} value={font}>
            {font}
          </ToolboxSelectItem>
        ))}
      </ToolboxSelect>
    </Box>
  );
};
