import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';

export const createWebComponent = <T>(Component: FunctionComponent<T>) =>
  class WebComponent extends reactToWebComponent(Component, React, ReactDOM) {
    constructor(props: Partial<T>) {
      super();
      Object.assign(this, props);
    }
  };
