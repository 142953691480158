import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useCanvasRef } from 'shared/ui/canvas';

export const WhiteboardContextMenu = () => {
  const canvasRef = useCanvasRef();

  useCanvasEvent('contextmenu', canvasRef, ({ evt }) => {
    evt.preventDefault();
  });

  return null;
};
