import { whiteboardModel } from 'entities/whiteboard';
import { useCallback, useEffect } from 'react';
import { useCanvasRef } from 'shared/ui/canvas';

export const RemoveNodeHotKeyListener = () => {
  const canvas = useCanvasRef();

  const selectedNodeIds = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeIds);
  const dispatch = whiteboardModel.useDispatch();

  const handleRemove = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Backspace' && canvas.current.container() === e.target) {
        e.preventDefault();
        if (selectedNodeIds.length) {
          selectedNodeIds.forEach((id) => dispatch(whiteboardModel.removeNode(id)));
        }
      }
    },
    [selectedNodeIds],
  );

  useEffect(() => {
    const container = canvas.current.container();

    container.addEventListener('keydown', handleRemove);

    return () => {
      container.removeEventListener('keydown', handleRemove);
    };
  }, [handleRemove]);

  return null;
};
