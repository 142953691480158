import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { whiteboardModel } from 'entities/whiteboard';
import { uuid } from 'shared/lib/uuid';

import { State, WhiteboardMode, WhiteboardNode, WhiteboardNodeProps } from './types';

export const initialState: State = {
  mode: WhiteboardMode.Edit,
  nodes: [],
  selectedNodeIds: [],
};

export const slice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    changeMode: (state, { payload }: PayloadAction<WhiteboardMode>) => {
      state.mode = payload;
    },
    loadNodes: (state, { payload }: PayloadAction<WhiteboardNode[]>) => {
      state.nodes = payload as Draft<WhiteboardNode>[];
    },
    createNode: (state, { payload: { type, draft, props } }: PayloadAction<WhiteboardNode>) => {
      const id = uuid();
      state.nodes = state.nodes
        .filter(({ draft }) => !draft)
        .concat({ type, draft: draft ?? true, props: { ...props, id } } as Draft<WhiteboardNode>);

      state.selectedNodeIds = [id];
    },
    removeNode: (state, { payload }: PayloadAction<string>) => {
      state.nodes = state.nodes.filter(({ type, props }) => {
        if (type === whiteboardModel.WhiteboardNodeType.Connector) {
          return props.id !== payload && ![props.source.id, props.target.id].includes(payload);
        }

        return props.id !== payload;
      });

      state.selectedNodeIds = [];
    },
    commitDrafts: (state) => {
      state.nodes.forEach((node) => {
        delete node.draft;
      });
    },
    updateNodeSelection: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedNodeIds = payload;
    },
    transformNode: (state, { payload }: PayloadAction<WhiteboardNodeProps>) => {
      state.nodes.forEach((shape) => {
        if (shape.props.id === payload.id) {
          shape.props = { ...shape.props, ...payload } as Draft<WhiteboardNodeProps>;
        }
      });
    },
    moveToTop: (state, { payload }: PayloadAction<string>) => {
      const targetIndex = state.nodes.findIndex(({ props }) => props.id === payload);
      state.nodes = [
        ...state.nodes.slice(0, targetIndex),
        ...state.nodes.slice(targetIndex + 1),
        state.nodes[targetIndex],
      ];
    },
  },
});

export const {
  changeMode,
  loadNodes,
  createNode,
  removeNode,
  commitDrafts,
  updateNodeSelection,
  transformNode,
  moveToTop,
} = slice.actions;
export const reducer = slice.reducer;
