import { Circle as CircleRef } from 'konva/lib/shapes/Circle';
import { PropsWithChildren } from 'react';
import { Circle, KonvaNodeEvents } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';

import { Config } from './model';

export type CircleNodeProps = KonvaNodeEvents & Config;

const CircleNodeInner = (props: CircleNodeProps) => {
  const ref = useNodeRef<CircleRef>();

  return <Circle {...props} ref={ref} />;
};

export const CircleNode = ({ children, ...props }: PropsWithChildren<CircleNodeProps>) => (
  <NodeRefProvider>
    <CircleNodeInner {...props} />
    {children}
  </NodeRefProvider>
);
