import { whiteboardModel } from 'entities/whiteboard';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useNodeRef } from 'shared/ui/node';

export type NodeTransformListenerProps = {
  disableX?: boolean;
  disableY?: boolean;
};

export const NodeTransformListener = ({ disableX = false, disableY = false }: NodeTransformListenerProps) => {
  const nodeRef = useNodeRef();
  const dispatch = whiteboardModel.useDispatch();

  const getSize = () => nodeRef.current.size();

  const getScale = () => {
    const { x: scaleX, y: scaleY } = nodeRef.current.scale();
    return { scaleX, scaleY };
  };

  const getPosition = () => nodeRef.current.position();

  const resetScale = () => nodeRef.current.scale({ x: 1, y: 1 });

  useCanvasEvent('transform', nodeRef, () => {
    const { width, height } = getSize();
    const { scaleX, scaleY } = getScale();

    resetScale();

    nodeRef.current.setAttrs({
      width: disableX ? undefined : Math.round(width * scaleX),
      height: disableY ? undefined : Math.round(height * scaleY),
    });
  });

  useCanvasEvent('transformend', nodeRef, () => {
    const node = nodeRef.current;

    const id = node.id();
    const { width, height } = getSize();
    const { x, y } = getPosition();
    const { scaleX, scaleY } = getScale();

    resetScale();

    dispatch(
      whiteboardModel.transformNode({
        id,
        x,
        y,
        width: disableX ? undefined : Math.round(width * scaleX),
        height: disableY ? undefined : Math.round(height * scaleY),
      }),
    );
  });

  return null;
};
