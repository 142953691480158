import { Tooltip } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { useFileStorage } from 'features/file-storage';
import { useCallback, useEffect } from 'react';
import VideoIcon from 'shared/assets/icons/Video.svg';
import { useFilePicker } from 'shared/lib/file-uploader';
import { ToggleButton, ToggleButtonProps } from 'shared/ui/toggle-button';
import { videoPlayerModel } from 'shared/ui/video-player';

export const AddVideoByButton = (props: ToggleButtonProps) => {
  const [files, uploadFile] = useFilePicker({ accept: 'video/*' });
  const { upload } = useFileStorage();

  const dispatch = whiteboardModel.useDispatch();

  const addVideo = useCallback(async () => {
    if (files?.length) {
      const { src } = await upload(files[0]);

      dispatch(
        whiteboardModel.createNode({
          type: whiteboardModel.WhiteboardNodeType.Video,
          props: { ...videoPlayerModel.defaultConfig, src, image: undefined },
        }),
      );
    }
  }, [files]);

  useEffect(() => {
    addVideo();
  }, [addVideo]);

  return (
    <ToggleButton {...props} onClick={uploadFile}>
      <Tooltip title="Add video">
        <VideoIcon />
      </Tooltip>
    </ToggleButton>
  );
};
