import { withProviders } from 'app/providers';
import { whiteboardModel } from 'entities/whiteboard';
import { useEffect } from 'react';
import { useChangeEffect } from 'shared/lib/change-effect';
import { CollaborativeWhiteboard } from 'widgets/collaborative-whiteboard';

type AppProps = {
  items: whiteboardModel.WhiteboardNode[];
  onItemsChange?: (items: whiteboardModel.WhiteboardNode[]) => void;
};

const App = ({ items, onItemsChange }: AppProps) => {
  const dispatch = whiteboardModel.useDispatch();
  const boardItems = whiteboardModel.useSelector(whiteboardModel.selectNodeList);

  useEffect(() => {
    const preventContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', preventContextMenu);

    return () => document.removeEventListener('contextmenu', preventContextMenu);
  }, []);

  useChangeEffect(() => {
    onItemsChange?.(boardItems);
  }, [boardItems, onItemsChange]);

  useEffect(() => {
    if (items) {
      dispatch(whiteboardModel.loadNodes(items));
    }
  }, [items, dispatch]);

  return <CollaborativeWhiteboard />;
};

export default withProviders(App);
