import { ComponentType } from 'react';
import { FontProvider } from 'shared/lib/font-loader';

const families = ['Merriweather', 'Montserrat', 'Droid Sans', 'Chilanka'];

export const withFonts = <T,>(Component: ComponentType<T>) => {
  return (hocProps: T) => (
    <FontProvider families={families}>
      <Component {...(hocProps as T)} />
    </FontProvider>
  );
};
