import { Box, useTheme } from '@mui/material';
import { Whiteboard, whiteboardModel } from 'entities/whiteboard';
import { FileStorageProvider, useFileStorage } from 'features/file-storage';
import { ZoneSelectionListener } from 'features/selection';
import { GlobalTransformListener } from 'features/transform';
import { ZoomListener } from 'features/zoom';
import { memo } from 'react';
import { ThemeProvider } from 'shared/lib/theme';
import { HotKeyListener } from 'widgets/hot-keys';
import { Toolbox } from 'widgets/toolbox';
import { WhiteboardContextMenu } from 'widgets/whiteboard-context-menu';
import { WhiteBoardCursor } from 'widgets/whiteboard-cursor';
import { WhiteboardNodeList } from 'widgets/whiteboard-node-list';

import useStyles from './styles';

export const CollaborativeWhiteboard = memo(() => {
  const styles = useStyles();
  const theme = useTheme();
  const storage = useFileStorage();

  const store = whiteboardModel.useStore();

  return (
    <Box sx={styles.root}>
      <Toolbox />
      <Whiteboard>
        <whiteboardModel.WhiteboardProvider store={store}>
          <ThemeProvider theme={theme}>
            <FileStorageProvider storage={storage}>
              <WhiteboardNodeList />
              <WhiteBoardCursor />
              <WhiteboardContextMenu />
              <ZoomListener />
              <ZoneSelectionListener />
              <HotKeyListener />
              <GlobalTransformListener />
            </FileStorageProvider>
          </ThemeProvider>
        </whiteboardModel.WhiteboardProvider>
      </Whiteboard>
    </Box>
  );
});
