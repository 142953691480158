import { whiteboardModel } from 'entities/whiteboard';
import { AddImageNodeByButton } from 'features/whiteboard/add-image-node';
import { AddShapeNodeByButton } from 'features/whiteboard/add-shape-node';
import { AddTextNodeByButton } from 'features/whiteboard/add-text-node';
import { AddVideoByButton } from 'features/whiteboard/add-video-node';
import { ToggleButtonGroup } from 'shared/ui/toggle-button-group';

export const AddNodeCommands = () => {
  const selectedNodeType = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeType);

  return (
    <ToggleButtonGroup exclusive value={selectedNodeType}>
      <AddTextNodeByButton value={whiteboardModel.WhiteboardNodeType.Text} />
      <AddImageNodeByButton value={whiteboardModel.WhiteboardNodeType.Image} />
      <AddVideoByButton value={whiteboardModel.WhiteboardNodeType.Video} />
      <AddShapeNodeByButton value="shapeList" />
    </ToggleButtonGroup>
  );
};
