import { Shape } from 'konva/lib/Shape';
import { createContext, MutableRefObject, PropsWithChildren, useContext, useRef } from 'react';

const NodeRefContext = createContext<MutableRefObject<Shape>>(null!);

export const NodeRefProvider = <T extends Shape>({ children }: PropsWithChildren<unknown>) => {
  const ref = useRef<T>(null!);

  return <NodeRefContext.Provider value={ref}>{children}</NodeRefContext.Provider>;
};

export const useNodeRef = <T extends Shape>() => useContext(NodeRefContext) as MutableRefObject<T>;
