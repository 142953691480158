import { whiteboardModel } from 'entities/whiteboard';
import { PropsWithChildren } from 'react';
import { useToggle } from 'shared/lib/toggle';
import { EditableTextInput } from 'shared/ui/editable-text-input';
import { TextNode, TextNodeProps } from 'shared/ui/text-node';

export type EditableTextNodeProps = TextNodeProps;

export const EditableTextNode = ({ onChange, children, ...props }: PropsWithChildren<EditableTextNodeProps>) => {
  const dispatch = whiteboardModel.useDispatch();

  const [isEditing, toggleEditing] = useToggle(false);

  const handleChange = (text: string) => {
    toggleEditing();
    dispatch(whiteboardModel.transformNode({ id: props.id, text }));
  };

  return isEditing ? (
    <EditableTextInput {...props} onChange={handleChange} />
  ) : (
    <TextNode {...props} onDblClick={toggleEditing} onChange={onChange}>
      {children}
    </TextNode>
  );
};
