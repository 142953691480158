import { whiteboardModel } from 'entities/whiteboard';
import { MouseEvent } from 'react';
import { ToggleButton } from 'shared/ui/toggle-button';
import { ToggleButtonGroup } from 'shared/ui/toggle-button-group';

export const DecorateText = () => {
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const dispatch = whiteboardModel.useDispatch();
  const id = selectedNodeProps?.id;
  const selectedTextDecoration = selectedNodeProps?.textDecoration;

  const handleChange = (e: MouseEvent<HTMLElement>, textDecoration: string) => {
    dispatch(whiteboardModel.transformNode({ id, textDecoration }));
  };

  return (
    <ToggleButtonGroup exclusive value={selectedTextDecoration} onChange={handleChange}>
      <ToggleButton variant="text" value="underline" sx={{ textDecoration: 'underline' }}>
        U
      </ToggleButton>
      <ToggleButton variant="text" value="line-through" sx={{ textDecoration: 'line-through' }}>
        S
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
