import { useTheme } from '@mui/material';
import { Node, NodeConfig } from 'konva/lib/Node';
import { Arrow as ConnectorRef } from 'konva/lib/shapes/Arrow';
import { Vector2d } from 'konva/lib/types';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Arrow, KonvaNodeEvents } from 'react-konva';
import { useCanvasRef } from 'shared/ui/canvas';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';
import { transformerModel } from 'shared/ui/transformer';

import { Config, ConnectorPoint } from './model';

type ConnectorProps = {
  source: ConnectorPoint;
  target: ConnectorPoint;
} & KonvaNodeEvents &
  Config;

const ConnectorInner = ({ source, target, ...props }: ConnectorProps) => {
  const { palette } = useTheme();

  const [sourceNode, setSourceNode] = useState<Node<NodeConfig>>();
  const [targetNode, setTargetNode] = useState<Node<NodeConfig>>();
  const [sourcePoints, setSourcePoints] = useState<number[]>([]);
  const [targetPoints, setTargetPoints] = useState<number[]>([]);

  const points = useMemo(() => [...sourcePoints, ...targetPoints], [sourcePoints, targetPoints]);

  const ref = useNodeRef<ConnectorRef>();
  const canvasRef = useCanvasRef();

  const getAnchorPoints = (type: string, node: Node<NodeConfig>): Vector2d => {
    const { x, y, height, width } = node.getClientRect({
      relativeTo: canvasRef.current as any,
    })!;

    const halfHeight = height / 2;
    const halfWidth = width / 2;

    switch (type as transformerModel.Anchor) {
      case transformerModel.Anchor.TopLeft:
        return { x, y };
      case transformerModel.Anchor.TopCenter:
        return { x: x + halfWidth, y };
      case transformerModel.Anchor.TopRight:
        return { x: x + width, y };
      case transformerModel.Anchor.MiddleLeft:
        return { x, y: y + halfHeight };
      case transformerModel.Anchor.MiddleRight:
        return { x: x + width, y: y + halfHeight };
      case transformerModel.Anchor.BottomLeft:
        return { x, y: y + height };
      case transformerModel.Anchor.BottomCenter:
        return { x: x + halfWidth, y: y + height };
      case transformerModel.Anchor.BottomRight:
        return { x: x + width, y: y + height };
      default:
        return { x: 0, y: 0 };
    }
  };

  const updateSourceAnchors = (anchor: string, node: Node<NodeConfig>) => {
    const points = getAnchorPoints(anchor, node);
    setSourcePoints([points.x, points.y]);
  };

  const updateTargetAnchors = (anchor: string, node: Node<NodeConfig>) => {
    const points = getAnchorPoints(anchor, node);
    setTargetPoints([points.x, points.y]);
  };

  useEffect(() => {
    setSourceNode(canvasRef.current.findOne(`#${source.id}`));
    setTargetNode(canvasRef.current.findOne(`#${target.id}`));
  }, []);

  useEffect(() => {
    if (sourceNode) {
      updateSourceAnchors(source.anchor, sourceNode);
      sourceNode.on('dragmove transform', () => {
        updateSourceAnchors(source.anchor, sourceNode);
      });
      return () => {
        sourceNode.off('dragmove transform');
      };
    }
  }, [sourceNode]);

  useEffect(() => {
    if (targetNode) {
      updateTargetAnchors(target.anchor, targetNode);
      targetNode.on('dragmove transform', () => {
        updateTargetAnchors(target.anchor, targetNode);
      });
      return () => {
        targetNode.off('dragmove transform');
      };
    }
  }, [targetNode]);

  return <Arrow {...props} ref={ref} points={points} stroke={palette.secondary.main} fill={palette.secondary.main} />;
};

export const Connector = ({ children, ...props }: PropsWithChildren<ConnectorProps>) => (
  <NodeRefProvider>
    <ConnectorInner {...props} />
    {children}
  </NodeRefProvider>
);
