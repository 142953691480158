import { Box, Toolbar as MuiToolbar } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { ChangeModeSelector } from 'features/change-mode';
import { RemoveNodeByButton } from 'features/whiteboard/remove-node';
import { Divider } from 'shared/ui/divider';
import { AddNodeCommands } from 'widgets/add-node-commands';
import { NodeToolCommands } from 'widgets/node-tool-commands';

import useStyles from './styles';

export const Toolbox = () => {
  const styles = useStyles();
  const selectedNode = whiteboardModel.useSelector(whiteboardModel.selectSelectedNode);
  const isEditMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeEdit);
  const canRemove = selectedNode && !selectedNode.draft && isEditMode;

  return (
    <MuiToolbar sx={styles.root}>
      <Box sx={styles.items}>
        <ChangeModeSelector />
        {isEditMode && <AddNodeCommands />}
        {isEditMode && <NodeToolCommands />}
        {canRemove && (
          <>
            <Divider />
            <RemoveNodeByButton />
          </>
        )}
      </Box>
    </MuiToolbar>
  );
};
