import { makeSx } from 'shared/lib/theme';

export default makeSx({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
});
