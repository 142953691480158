import { circleNodeModel } from 'shared/ui/circle-node';
import { connectorModel } from 'shared/ui/connector';
import { imageNodeModel } from 'shared/ui/image-node';
import { rectNodeModel } from 'shared/ui/rect-node';
import { textNodeModel } from 'shared/ui/text-node';
import { triangleNodeModel } from 'shared/ui/triangle-node';
import { videoPlayerModel } from 'shared/ui/video-player';

export enum WhiteboardNodeType {
  Text = 'text',
  Rect = 'rectangle',
  Circle = 'circle',
  Triangle = 'triangle',
  Image = 'image',
  Video = 'video',
  Connector = 'connector',
}

export type WhiteboardNodeProps =
  | textNodeModel.Config
  | rectNodeModel.Config
  | circleNodeModel.Config
  | triangleNodeModel.Config
  | imageNodeModel.Config
  | videoPlayerModel.Config
  | connectorModel.Config;

export type WhiteboardBaseNode = {
  draft?: boolean;
};

export type WhiteboardTextNode = {
  type: WhiteboardNodeType.Text;
  props: textNodeModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardRectNode = {
  type: WhiteboardNodeType.Rect;
  props: rectNodeModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardCircleNode = {
  type: WhiteboardNodeType.Circle;
  props: circleNodeModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardTriangleNode = {
  type: WhiteboardNodeType.Triangle;
  props: triangleNodeModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardImageNode = {
  type: WhiteboardNodeType.Image;
  props: imageNodeModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardVideoPlayer = {
  type: WhiteboardNodeType.Video;
  props: videoPlayerModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardConnector = {
  type: WhiteboardNodeType.Connector;
  props: connectorModel.Config;
} & WhiteboardBaseNode;

export type WhiteboardNode =
  | WhiteboardTextNode
  | WhiteboardRectNode
  | WhiteboardCircleNode
  | WhiteboardTriangleNode
  | WhiteboardImageNode
  | WhiteboardConnector
  | WhiteboardVideoPlayer;

export enum WhiteboardMode {
  View,
  Edit,
  Connect,
}

export type State = {
  mode: WhiteboardMode;
  nodes: WhiteboardNode[];
  selectedNodeIds: string[];
};
