import { createContext, PropsWithChildren, useContext } from 'react';

import { FileStorage } from '../model';

const Context = createContext<FileStorage>(null!);

export type FileStorageProviderProps = {
  storage: FileStorage;
};

export const FileStorageProvider = ({ children, storage }: PropsWithChildren<FileStorageProviderProps>) => {
  return <Context.Provider value={storage}>{children}</Context.Provider>;
};

export const useFileStorage = () => useContext(Context);
