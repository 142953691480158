import { makeSx } from 'shared/lib/theme';

export default makeSx({
  pickerPaper: ({ palette, shadows }) => ({
    zIndex: 1,
    marginTop: 0.25,
    position: 'absolute',
    boxShadow: shadows[1],
    border: `1px solid ${palette.grey[2]}`,
    padding: 1.25,
  }),
  backdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
});
