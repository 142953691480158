import { Box, Paper } from '@mui/material';
import { CirclePicker, CirclePickerProps } from 'react-color';

import { colors } from './model';
import useStyles from './styles';

export type ColorPickerProps = { opened: boolean; onClose: () => void } & CirclePickerProps;

export const ColorPicker = ({ opened, onClose, ...pickerProps }: ColorPickerProps) => {
  const styles = useStyles();

  return opened ? (
    <Paper sx={styles.pickerPaper} elevation={1}>
      <Box sx={styles.backdrop} onClick={onClose} />
      <CirclePicker {...pickerProps} circleSpacing={8} colors={colors} />
    </Paper>
  ) : (
    <></>
  );
};
