import { FunctionComponent } from 'react';

export type SwitchDefaultProps = {
  componentName?: 'default';
};

export const SwitchDefault: FunctionComponent<SwitchDefaultProps> = ({ children }) => {
  return <>{children}</>;
};

SwitchDefault.defaultProps = {
  componentName: 'default',
};
