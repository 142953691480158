import { alpha, useTheme } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import Konva from 'konva';
import { Rect as RectRef } from 'konva/lib/shapes/Rect';
import { IRect } from 'konva/lib/types';
import { useEffect, useRef, useState } from 'react';
import { Rect } from 'react-konva';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useCanvasRef } from 'shared/ui/canvas';

import { SelectionZone } from '../model';

export const ZoneSelectionListener = () => {
  const { palette } = useTheme();

  const nodes = whiteboardModel.useSelector(whiteboardModel.selectNodeList);
  const isEditMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeEdit);
  const dispatch = whiteboardModel.useDispatch();

  const canvasRef = useCanvasRef();
  const selectionRef = useRef<RectRef>(null);

  const [selectionZone, setSelectionZone] = useState<SelectionZone>({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const getPos = (from: number, to: number) => Math.min(from, to);
  const getDistance = (from: number, to: number) => Math.abs(from - to);
  const getSelectionBox = () => selectionRef.current!.getClientRect();
  const getPointerPosition = () => canvasRef.current.getRelativePointerPosition()!;
  const hasIntersectionWith = (box: IRect) => Konva.Util.haveIntersection(box, getSelectionBox());

  const updateSelectionZone = (changes: Partial<SelectionZone>) => {
    setSelectionZone((prev) => ({ ...prev, ...changes }));
  };

  useCanvasEvent('mousedown', canvasRef, ({ target }) => {
    if (target.getStage() === target) {
      const { x, y } = getPointerPosition();

      updateSelectionZone({ x1: x, x2: x, y1: y, y2: y, visible: true });
    }
  });

  useCanvasEvent('mousemove', canvasRef, () => {
    if (selectionZone.visible) {
      const { x, y } = getPointerPosition();

      updateSelectionZone({ x2: x, y2: y });
    }
  });

  useCanvasEvent('mouseup', canvasRef, () => {
    if (selectionZone.visible) {
      const intersected = nodes
        .filter(({ type, props: { id } }) => {
          if (type === whiteboardModel.WhiteboardNodeType.Connector) {
            return false;
          }
          const node = canvasRef.current.findOne(`#${id}`);
          const nodeBox = node.getClientRect();

          return hasIntersectionWith(nodeBox);
        })
        .map(({ props: { id } }) => id);

      dispatch(whiteboardModel.updateNodeSelection(intersected));
    }
    updateSelectionZone({ visible: false });
  });

  useEffect(() => {
    const { x1, x2, y1, y2, visible } = selectionZone;

    const [x, y] = [getPos(x1, x2), getPos(y1, y2)];
    const [width, height] = [getDistance(x1, x2), getDistance(y1, y2)];
    selectionRef.current?.setAttrs({ visible, x, y, width, height });
    selectionRef.current?.getLayer()?.batchDraw();
  }, [selectionZone]);

  return (
    <Rect
      ref={selectionRef}
      visible={isEditMode}
      fill={alpha(palette.secondary.main, 0.2)}
      stroke={palette.secondary.main}
      strokeWidth={1}
    />
  );
};
