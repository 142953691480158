import { ChangeFontStyle } from 'features/node/change-font-style';
import { ChangeFontWeight } from 'features/node/change-font-weight';
import { DecorateText } from 'features/node/decorate-text';

export const StyleFontCommands = () => {
  return (
    <>
      <ChangeFontWeight />
      <ChangeFontStyle />
      <DecorateText />
    </>
  );
};
