import { Rect as RectRef } from 'konva/lib/shapes/Rect';
import { PropsWithChildren } from 'react';
import { KonvaNodeEvents, Rect } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';

import { Config } from './model';

export type RectNodeProps = KonvaNodeEvents & Config;

const RectNodeInner = (props: RectNodeProps) => {
  const ref = useNodeRef<RectRef>();

  return <Rect {...props} ref={ref} />;
};

export const RectNode = ({ children, ...props }: PropsWithChildren<RectNodeProps>) => (
  <NodeRefProvider>
    <RectNodeInner {...props} />
    {children}
  </NodeRefProvider>
);
