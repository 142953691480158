import { whiteboardModel } from 'entities/whiteboard';
import { ComponentType, useReducer } from 'react';

const { reducer, initialState } = whiteboardModel;

export const withStore = <T,>(Component: ComponentType<T>) => {
  return (hocProps: T) => {
    const store = useReducer(reducer, initialState);

    return (
      <whiteboardModel.WhiteboardProvider store={store}>
        <Component {...(hocProps as T)} />
      </whiteboardModel.WhiteboardProvider>
    );
  };
};
