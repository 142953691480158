import { whiteboardModel } from 'entities/whiteboard';
import { useEffect } from 'react';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { useCanvasRef } from 'shared/ui/canvas';

export const ZoomListener = () => {
  const canvasRef = useCanvasRef();
  const isViewMode = whiteboardModel.useSelector(whiteboardModel.selectIsModeView);

  useEffect(() => {
    canvasRef.current.setDraggable(isViewMode);
  }, [canvasRef, isViewMode]);

  useCanvasEvent('wheel', canvasRef, ({ evt }) => {
    if (isViewMode) {
      evt.preventDefault();
      const { current: stage } = canvasRef;
      const { x, y } = stage.getPosition();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()!;

      const scaleBy = 1.05;

      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: pointerX / oldScale - x / oldScale,
        y: pointerY / oldScale - y / oldScale,
      };

      const newScale = evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });

      const s = stage.getPointerPosition()!;
      const m = mousePointTo;

      stage.x(-(m.x - s.x / newScale) * newScale);
      stage.y(-(m.y - s.y / newScale) * newScale);
    }
  });

  return null;
};
