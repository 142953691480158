import { whiteboardModel } from 'entities/whiteboard';
import { useFileStorage } from 'features/file-storage';
import { useCallback, useEffect, useState } from 'react';
import { useCanvasRef } from 'shared/ui/canvas';
import { imageNodeModel } from 'shared/ui/image-node';
import { textNodeModel } from 'shared/ui/text-node';

export const ClipboardListener = () => {
  const dispatch = whiteboardModel.useDispatch();

  const canvas = useCanvasRef();

  const selectedNodeList = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeList);
  const [bufferedNodeList, setBufferedNodeList] = useState<whiteboardModel.WhiteboardNode[]>([]);

  const { upload } = useFileStorage();

  const handleClipboardCopy = useCallback(async () => {
    await navigator.clipboard.write([]);

    if (selectedNodeList.length) {
      setBufferedNodeList(selectedNodeList);
    }
  }, [selectedNodeList]);

  const handleClipboardPaste = useCallback(async () => {
    const externalContentList = await navigator.clipboard.read();

    if (bufferedNodeList.length) {
      bufferedNodeList.forEach(({ type, props: { id, x, y, ...nodeProps } }) => {
        dispatch(
          whiteboardModel.createNode({
            type,
            draft: false,
            props: { ...nodeProps, x: x! + 15, y: y! + 15 },
          } as whiteboardModel.WhiteboardNode),
        );
      });
    } else if (externalContentList.length) {
      for (const item of externalContentList) {
        if (item.types.includes('image/png')) {
          const blob = await item.getType('image/png');
          const file = new File([blob], `image_${new Date().getTime()}`);

          const { src } = await upload(file);

          dispatch(
            whiteboardModel.createNode({
              type: whiteboardModel.WhiteboardNodeType.Image,
              props: { ...imageNodeModel.defaultConfig, src },
              draft: false,
            }),
          );
        } else if (item.types.includes('text/plain')) {
          const text = await navigator.clipboard.readText();

          dispatch(
            whiteboardModel.createNode({
              type: whiteboardModel.WhiteboardNodeType.Text,
              props: { ...textNodeModel.defaultConfig, text },
              draft: false,
            }),
          );
        }
      }
    }

    setBufferedNodeList([]);
    await navigator.clipboard.write([]);
  }, [bufferedNodeList]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case 'c': {
            e.preventDefault();
            handleClipboardCopy();
            break;
          }
          case 'v': {
            e.preventDefault();
            handleClipboardPaste();
            break;
          }
        }
      }
    },
    [handleClipboardCopy, handleClipboardPaste],
  );

  useEffect(() => {
    const container = canvas.current.container();

    container.style = `outline: 0px solid transparent; cursor: default`;
    container.contentEditable = 'true';

    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return null;
};
