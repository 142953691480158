import { Tooltip } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { useFileStorage } from 'features/file-storage';
import { useCallback, useEffect } from 'react';
import ImageIcon from 'shared/assets/icons/Image.svg';
import { useFilePicker } from 'shared/lib/file-uploader';
import { imageNodeModel } from 'shared/ui/image-node';
import { ToggleButton, ToggleButtonProps } from 'shared/ui/toggle-button';

export const AddImageNodeByButton = (props: ToggleButtonProps) => {
  const [files, uploadFile] = useFilePicker({ accept: 'image/*' });
  const { upload } = useFileStorage();

  const dispatch = whiteboardModel.useDispatch();

  const addImage = useCallback(async () => {
    if (files?.length) {
      const { src } = await upload(files[0]);

      dispatch(
        whiteboardModel.createNode({
          type: whiteboardModel.WhiteboardNodeType.Image,
          props: { ...imageNodeModel.defaultConfig, src },
        }),
      );
    }
  }, [files]);

  useEffect(() => {
    addImage();
  }, [addImage]);

  return (
    <ToggleButton {...props} onClick={uploadFile}>
      <Tooltip title="Add image">
        <ImageIcon />
      </Tooltip>
    </ToggleButton>
  );
};
