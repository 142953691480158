import AddLinkIcon from '@mui/icons-material/AddLink';
import NearMeIcon from '@mui/icons-material/NearMe';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { Box, MenuItemProps, SelectChangeEvent, Tooltip, TooltipProps } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { ToolboxSelect, ToolboxSelectItem } from 'shared/ui/toolbox-select';

import useStyles from './styles';

const ChangeModeItem = ({ title, ...itemProps }: Pick<TooltipProps, 'title'> & MenuItemProps) => {
  const styles = useStyles();

  return (
    <Tooltip title={title} placement="right">
      <ToolboxSelectItem {...itemProps} sx={styles.item} />
    </Tooltip>
  );
};

export const ChangeModeSelector = () => {
  const styles = useStyles();

  const dispatch = whiteboardModel.useDispatch();
  const selectedMode = whiteboardModel.useSelector(whiteboardModel.selectMode);

  const handleChange = ({ target: { value: mode } }: SelectChangeEvent<any>) => {
    dispatch(whiteboardModel.changeMode(mode));
  };

  return (
    <Box sx={styles.root}>
      <ToolboxSelect value={selectedMode} onChange={handleChange} sx={{ width: 75 }}>
        <ChangeModeItem title="Edit" value={whiteboardModel.WhiteboardMode.Edit}>
          <NearMeIcon />
        </ChangeModeItem>
        <ChangeModeItem title="Pan and zoom" value={whiteboardModel.WhiteboardMode.View}>
          <PanToolAltIcon />
        </ChangeModeItem>
        <ChangeModeItem title="Connect nodes" value={whiteboardModel.WhiteboardMode.Connect}>
          <AddLinkIcon />
        </ChangeModeItem>
      </ToolboxSelect>
    </Box>
  );
};
