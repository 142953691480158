import { EditableTextNodeProps } from 'features/edit-text-node';
import { makeSx } from 'shared/lib/theme';

export default makeSx<Partial<EditableTextNodeProps>>(
  ({ fontFamily, fontSize, fontStyle, fontVariant, fill, width, textDecoration, align }) => ({
    root: {
      width,
      '& .MuiInput-root': {
        padding: 1.5,
      },
    },
    textArea: () => ({
      fontFamily,
      fontSize,
      fontStyle,
      textDecoration,
      textAlign: align as any,
      color: fill,
      fontWeight: fontVariant,
      lineHeight: 1,
    }),
  }),
);
