import { useTheme } from '@mui/material';
import Konva from 'konva';
import { Image as VideoRef } from 'konva/lib/shapes/Image';
import { PropsWithChildren, useEffect } from 'react';
import { Image as KonvaImage, KonvaNodeEvents } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';

import { useVideoPlayer, VideoPlayerProvider } from './lib';
import { Config } from './model';

export type VideoNodeProps = KonvaNodeEvents & Config;

const VideoNodeInner = (props: VideoNodeProps) => {
  const ref = useNodeRef<VideoRef>();
  const [video] = useVideoPlayer();
  const theme = useTheme();

  useEffect(() => {
    const anim = new Konva.Animation(() => {}, ref.current?.getLayer());
    anim.start();
    return () => {
      anim.stop();
    };
  }, [ref]);

  return <KonvaImage {...props} strokeWidth={1} stroke={theme.palette.grey[700]} ref={ref} image={video} />;
};

export const VideoPlayer = ({ children, ...props }: PropsWithChildren<VideoNodeProps>) => (
  <NodeRefProvider>
    <VideoPlayerProvider src={props.src}>
      <VideoNodeInner {...props} />
      {children}
    </VideoPlayerProvider>
  </NodeRefProvider>
);
