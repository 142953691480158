import { Config } from './types';

export const defaultConfig: Config = {
  x: 50,
  y: 50,
  width: 100,
  height: 100,
  stroke: 'black',
  strokeWidth: 1,
  fill: 'transparent',
};
