import { AnyAction } from '@reduxjs/toolkit';
import { createContext, Dispatch, PropsWithChildren, useContext, useMemo } from 'react';

import { State } from './types';

type Context = [state: State, dispatch: Dispatch<AnyAction>];

const Context = createContext<Context>(null!);

type WhiteboardProviderProps = {
  store: Context;
};

export const WhiteboardProvider = ({ children, store }: PropsWithChildren<WhiteboardProviderProps>) => {
  return <Context.Provider value={store}>{children}</Context.Provider>;
};

export const useStore = () => useContext(Context);
export const useState = () => useStore()[0];
export const useDispatch = () => useContext(Context)[1];
export const useSelector = <T,>(selector: (state: State) => T) => {
  const state = useState();
  const selected = useMemo(() => selector(state), [state]);

  return selected;
};
