import { fileStorageModel, FileStorageProvider } from 'features/file-storage';
import { ComponentType } from 'react';

export type WithFileStorage = {
  storage?: fileStorageModel.FileStorage;
};

export const withFileStorage = <T,>(Component: ComponentType<T>) => {
  return ({ storage, ...hocProps }: WithFileStorage & T) => (
    <FileStorageProvider storage={storage!}>
      <Component {...(hocProps as T)} />
    </FileStorageProvider>
  );
};
