import { RegularPolygon as TriangleRef } from 'konva/lib/shapes/RegularPolygon';
import { PropsWithChildren } from 'react';
import { KonvaNodeEvents, RegularPolygon } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';

import { Config } from './model';

export type TriangleNodeProps = KonvaNodeEvents & Config;

const TriangleNodeInner = (props: TriangleNodeProps) => {
  const ref = useNodeRef<TriangleRef>();

  return <RegularPolygon {...props} ref={ref} />;
};

export const TriangleNode = ({ children, ...props }: PropsWithChildren<TriangleNodeProps>) => (
  <NodeRefProvider>
    <TriangleNodeInner {...props} />
    {children}
  </NodeRefProvider>
);
