import { Image as ImageRef } from 'konva/lib/shapes/Image';
import { PropsWithChildren } from 'react';
import { Image, KonvaNodeEvents } from 'react-konva';
import { NodeRefProvider, useNodeRef } from 'shared/ui/node';
import useImage from 'use-image';

import { Config } from './model';

export type ImageNodeProps = KonvaNodeEvents & Config;

const ImageNodeInner = (props: ImageNodeProps) => {
  const ref = useNodeRef<ImageRef>();
  const [image] = useImage(props.src);

  return <Image {...props} ref={ref} image={image} />;
};

export const ImageNode = ({ children, ...props }: PropsWithChildren<ImageNodeProps>) => (
  <NodeRefProvider>
    <ImageNodeInner {...props} />
    {children}
  </NodeRefProvider>
);
