import { makeSx } from 'shared/lib/theme';

export default makeSx({
  root: ({ palette }) => ({
    '& svg.MuiSvgIcon-root ': {
      fontSize: 20,
      verticalAlign: 'middle',
      color: palette.secondary.main,
    },
  }),
  item: ({ palette }) => ({
    '& svg': {
      fontSize: 20,
      verticalAlign: 'middle',
      color: palette.grey[6],
    },
  }),
  icon: {
    height: '100%',
    width: '100%',
  },
});
