import { whiteboardModel } from 'entities/whiteboard';
import { useCallback, useEffect } from 'react';
import { useCanvasRef } from 'shared/ui/canvas';

export const ModeChangeListener = () => {
  const canvas = useCanvasRef();
  const dispatch = whiteboardModel.useDispatch();

  const handleKeyUp = useCallback(() => {
    dispatch(whiteboardModel.changeMode(whiteboardModel.WhiteboardMode.Edit));
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (!e.repeat) {
      if (e.altKey) {
        dispatch(whiteboardModel.changeMode(whiteboardModel.WhiteboardMode.View));
      } else if (e.ctrlKey || e.metaKey) {
        dispatch(whiteboardModel.changeMode(whiteboardModel.WhiteboardMode.Connect));
      }
    }
  }, []);

  useEffect(() => {
    const container = canvas.current.container();

    container.addEventListener('keyup', handleKeyUp);
    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keyup', handleKeyUp);
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas]);

  return null;
};
