import { KonvaEventListener } from 'konva/lib/Node';
import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';
import { MutableRefObject, useEffect, useRef } from 'react';

type KonvaEventMap = GlobalEventHandlersEventMap & {
  transform: Event;
  transformend: Event;
  dragmove: Event;
};

type KonvaEvent<K extends keyof KonvaEventMap> = KonvaEventMap[K];
type KonvaEventType = keyof KonvaEventMap;

export const useCanvasEvent = <E extends KonvaEventType, S extends Shape | Stage>(
  name: E,
  ref: MutableRefObject<S>,
  handler: KonvaEventListener<S, KonvaEvent<E>>,
) => {
  const memoizedHandler = useRef<KonvaEventListener<S, KonvaEvent<E>>>(null!);

  useEffect(() => {
    memoizedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const { current } = ref;

    current.on(name, (e) => (memoizedHandler as any).current?.(e));

    return () => {
      current.off(name);
    };
  }, [name, ref]);
};
