import { whiteboardModel } from 'entities/whiteboard';
import { transformerModel } from 'shared/ui/transformer';

export const useAnchors = (shapeType?: whiteboardModel.WhiteboardNodeType): transformerModel.Anchor[] => {
  switch (shapeType) {
    case whiteboardModel.WhiteboardNodeType.Text:
      return [transformerModel.Anchor.MiddleLeft, transformerModel.Anchor.MiddleRight];
    case whiteboardModel.WhiteboardNodeType.Circle:
    case whiteboardModel.WhiteboardNodeType.Triangle:
    case whiteboardModel.WhiteboardNodeType.Rect:
    case whiteboardModel.WhiteboardNodeType.Image:
    case whiteboardModel.WhiteboardNodeType.Video:
      return [
        transformerModel.Anchor.TopLeft,
        transformerModel.Anchor.TopCenter,
        transformerModel.Anchor.TopRight,
        transformerModel.Anchor.MiddleLeft,
        transformerModel.Anchor.MiddleRight,
        transformerModel.Anchor.BottomLeft,
        transformerModel.Anchor.BottomCenter,
        transformerModel.Anchor.BottomRight,
      ];
    default:
      return [];
  }
};
