import { TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { InjectedHtml } from 'shared/ui/injected-html';
import { TextNodeProps } from 'shared/ui/text-node';

import useStyles from './styles';

export type EditableTextInputProps = TextNodeProps;

export const EditableTextInput = ({ text, x, y, onChange, ...styleProps }: EditableTextInputProps) => {
  const styles = useStyles(styleProps);

  const [value, setValue] = useState(text);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleBlur = () => {
    onChange(value);
  };

  return (
    <InjectedHtml groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
      <TextField
        multiline
        sx={styles.root}
        inputProps={{ sx: styles.textArea }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </InjectedHtml>
  );
};
