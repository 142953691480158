import { useTheme } from '@mui/material';
import Konva from 'konva';
import { PropsWithChildren } from 'react';
import { Html } from 'react-konva-utils';
import { ThemeProvider } from 'shared/lib/theme';

type InjectedHtmlProps = {
  groupProps?: Konva.ContainerConfig;
  divProps?: any;
  transform?: boolean;
};

export const InjectedHtml = ({ children, ...props }: PropsWithChildren<InjectedHtmlProps>) => {
  const theme = useTheme();

  return (
    <Html {...props}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Html>
  );
};
