import { ComponentType } from 'react';
import { darkTheme, lightTheme, ThemeProvider } from 'shared/lib/theme';

export type WithTheme = {
  dark?: boolean;
};

export const withTheme = <T,>(Component: ComponentType<T>) => {
  return ({ dark, ...hocProps }: WithTheme & T) => (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <Component {...(hocProps as T)} />
    </ThemeProvider>
  );
};
