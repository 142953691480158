import { PaletteOptions } from '@mui/material/styles/createPalette';

export const paletteConfig: PaletteOptions = {
  primary: {
    main: '#FB7035',
  },
  secondary: {
    main: '#566BD6',
  },
  grey: {
    1: '#FFFFFF',
    2: '#F8F8FA',
    3: '#E0E0EC',
    4: '#BABACE',
    5: '#9696AB',
    6: '#616174',
    7: '#424250',
    8: '#2C2C38',
    10: '#191924',
  },
  red: {
    3: '#F68989',
    6: '#D91A1A',
  },
  blue: {
    1: '#E6EBF8',
  },
  action: {
    selected: '#566BD6',
  },
};
