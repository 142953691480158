import { Box, Button } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { ChangeEvent } from 'react';
import MinusIcon from 'shared/assets/icons/Minus.svg';
import PlusIcon from 'shared/assets/icons/Plus.svg';
import { ToolboxInput } from 'shared/ui/toolbox-input';

import useStyles from './styles';

export const ChangeFontSize = () => {
  const styles = useStyles();
  const dispatch = whiteboardModel.useDispatch();
  const selectedNodeProps = whiteboardModel.useSelector(whiteboardModel.selectSelectedNodeProps);
  const id = selectedNodeProps?.id;
  const selectedFontSize = selectedNodeProps?.fontSize;

  const increment = () => {
    dispatch(whiteboardModel.transformNode({ id, fontSize: selectedFontSize + 1 }));
  };

  const decrement = () => {
    dispatch(whiteboardModel.transformNode({ id, fontSize: selectedFontSize - 1 }));
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch(whiteboardModel.transformNode({ id, fontSize: +value }));
  };

  return (
    <Box sx={styles.root}>
      <Button sx={styles.control} onClick={decrement}>
        <MinusIcon />
      </Button>
      <ToolboxInput type="number" inputProps={{ min: 1 }} value={selectedFontSize} onChange={handleChange} />
      <Button sx={styles.control} onClick={increment}>
        <PlusIcon />
      </Button>
    </Box>
  );
};
