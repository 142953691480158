import { Config } from './types';

export const defaultConfig: Config = {
  x: 100,
  y: 100,
  height: 150,
  width: 250,
  image: undefined,
  src: '',
};
