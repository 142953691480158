import { Menu, MenuItem } from '@mui/material';
import { whiteboardModel } from 'entities/whiteboard';
import { Vector2d } from 'konva/lib/types';
import { useMemo, useState } from 'react';
import { useCanvasEvent } from 'shared/lib/canvas-events';
import { InjectedHtml } from 'shared/ui/injected-html';
import { useNodeRef } from 'shared/ui/node';

export const NodeContextMenu = () => {
  const nodeRef = useNodeRef();

  const selectedNode = whiteboardModel.useSelector(whiteboardModel.selectSelectedNode)!;
  const isSelected = useMemo(() => selectedNode?.props.id === nodeRef.current?.id(), [selectedNode, nodeRef]);

  const dispatch = whiteboardModel.useDispatch();

  const [position, setPosition] = useState<Vector2d>();

  const close = () => setPosition(undefined);

  const handleDuplicate = () => {
    const { x, y } = selectedNode.props;

    dispatch(
      whiteboardModel.createNode({
        ...selectedNode,
        draft: false,
        props: { ...selectedNode.props, x: x! + 15, y: y! + 15 },
      } as whiteboardModel.WhiteboardNode),
    );
    close();
  };

  const handleRemove = () => {
    dispatch(whiteboardModel.removeNode(selectedNode.props.id!));
    close();
  };

  useCanvasEvent('contextmenu', nodeRef, ({ evt }) => {
    evt.preventDefault();
    if (isSelected) {
      setPosition({ x: evt.pageX, y: evt.pageY });
    }
  });

  return (
    <InjectedHtml>
      <Menu
        elevation={1}
        open={!!position}
        onClose={close}
        anchorReference="anchorPosition"
        anchorPosition={position ? { top: position.y, left: position.x } : position}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>
    </InjectedHtml>
  );
};
